import { dataServiceQuery, configWithAuth } from '@/store/api_configs'
import IndexedDb from '@/utils/WorksheetDB'
import axios from 'axios'
import BatteryMeasurement from './BatteryMeasurement'
import { STATUS } from "@/repository/Contants";

/**
 * OfflineBatteryItem class manages data about
 * battery type worksheet items (download, upload, save, load)
 * and provide data structure for uniform handling
 */
export default class OfflineBatteryItem {

    indexedDb = null // stores IndexedDb object
    id = null
    worksheetId = null
    batteryItemData = {}
    groupMeasurementData = {}
    examinedProperties = {}
    measurements = {}
    cellData = []
    parts = null
    worksheetObjectReference = null
    temporaryOfflineBatteryItem = false

    /**
     * Initialize Object
     * @param {int} id  Id of worksheet item
     * @param {int} worksheetId Id of worksheet
     * @param {Object} batteryData Battery data from list view. If not provided needs to load or download the data manually.
     * @param {IndexedDb} indexedDb IndexedDB object for offline data management. Auto generated if not set,
     * but recommended to use one for all object, because multiple transactions can cause bad things.
     * @param {Object} worksheetObject Optional reference to worksheet includes this item.
     */
    constructor(id, worksheetId, batteryData, indexedDb = null, worksheetObject = null){
        this.worksheetObjectReference = worksheetObject
        this.worksheetId = worksheetId
        this.batteryItemData = batteryData
        this.id = id
        if(this.id<0){
          this.temporaryOfflineBatteryItem = true
        }
        if(batteryData){
          //preprocess batteryData (add some extra lines to make it work much easier)
          if(batteryData.parts){
            //map parts to another format (without it, it won't work)
            this.importParts(batteryData.parts)
          }
          if(batteryData.examinedProperties){
              this.examinedProperties = Object.assign({},batteryData)
              this.examinedProperties.examinedProperties.forEach(prop=>{
                  if(!prop.problems) { prop.problems = [] }
                  prop.problems.forEach(problem=>{
                      if(!problem.selected.title){
                        Object.assign(problem.selected,prop.property.possibleProblems.filter((possibleProblem)=> possibleProblem.id === problem.selectedId)[0])
                      }
                      if(problem.operation_complete === undefined){
                        problem.operation_complete = problem.selected.operation_complete
                      }
                  })
              })
              delete this.examinedProperties.battery
              delete this.batteryItemData.examinedProperties
          } else {
            this.examinedProperties = Object.assign({},batteryData)
          }
          if(batteryData.measurements && batteryData.cells){
            //generate BatteryMeasurement objects
            this.downloadMeasurements({measurements: batteryData.measurements,cells: batteryData.cells})
            delete batteryData.cells
            delete batteryData.measurements
            delete this.examinedProperties.measurements
          }
        }
        if (indexedDb)
            this.indexedDb = indexedDb
        else
            this.indexedDb = new IndexedDb()
    }
    /**
     * Returns if battery item is able to edit
     * @returns {boolean}
     */
    calculateIfEditAllowed(){
      return !this.examinedProperties.is_finished &&
      this.worksheetObjectReference.worksheetData.preparedData.status < STATUS.CLOSED &&
      (this.isOwnWorksheet()
      || JSON.parse(localStorage.getItem('service_app_user')).abilities['access-sysadmin']
      )
    }

    isOwnWorksheet(){
      return !this.worksheetObjectReference || this.worksheetObjectReference.worksheetData.preparedData.userAssignment.some(user=>JSON.parse(localStorage.getItem('service_app_user')).__uid === user.user.id)
    }
    /**
     * Map provided parts array and put it into global parts property in the correct format
     * @param {Object} parts Raw parts array from server
     */
    importParts(parts){
      this.parts = parts.map((item)=>{
          const newPartObject = {...item.part, ...item};
          newPartObject.qty = parseInt(newPartObject.qty||1)
          newPartObject['part_id'] = item.part.id
          newPartObject.id = item.id
          delete newPartObject.part;
          return newPartObject;
      })
    }
    /**
     * Transform parts data back to the original format and return it. Result is processable for api only.
     * @returns
     */
    getPartsForUpload(){
        const partsPostData = [];
        for (let i = 0; i < (this.parts || []).length; i++) {
          const item = this.parts[i];
          partsPostData.push({
            id: item.id || null,
            'part_id': item['part_id'],
            warehouse_id: item.warehouse_id,
            qty: item.qty,
            part: {...item, id: item['part_id']}
        })
        }
        return partsPostData
    }
    /**
     * Download base data about battery form server
     */
    async downloadBatteryItemData(){
        this.batteryItemData = (await axios(dataServiceQuery("worksheet", "get-batteries", Math.abs(this.worksheetId)))).data.data[this.id]
    }
    /**
     * Download examined properties from server
     */
    async downloadExaminedProperties(){
        this.examinedProperties = (await axios(dataServiceQuery("worksheet", "getExaminedBatteryPropsByWorksheetItem", this.id))).data.data
    }
    /**
     * Download picture data about battery problems (not pictures just url-s)
     */
    async downloadProblemImages(){
      const examinedProperties = (await axios(dataServiceQuery("worksheet", "getProblemImagesByWorksheetItem",{query: this.id}))).data.data
      console.log(examinedProperties)
      this.examinedProperties.problemImages = examinedProperties
    }
    /**
     * Download measurements data and create BatteryMeasurement object from it
     * @param {Object} downloadData If provided, this data will be used instead of communicating with server
     */
    async downloadMeasurements(downloadData = null){
        this.measurements = {}
        if(downloadData){
            this.cellData = downloadData.cells
            for (let i = 0; i < downloadData.measurements.length; i++){
                const element = downloadData.measurements[i]
                if(!this.measurements[element['group_id']||0])
                    this.measurements[element['group_id']||0] = []
                if(!this.measurements[element['group_id']||0][i])
                    this.measurements[element['group_id']||0].push(new BatteryMeasurement(element.cellCount || parseInt(this.batteryItemData.battery.voltage/2), downloadData.measurements[i], null, element['group_id']||0))
                Object.assign(this.measurements[element['group_id']||0], element)
            }
        }else{
            downloadData = (await axios(dataServiceQuery("worksheet", "getMeasurementsByWorksheetItem", this.id))).data.data
            this.cellData = downloadData.cells
            for (let i = 0; i < downloadData.measurements.length; i++){
                const element = downloadData.measurements[i]
                if(!this.measurements[element['group_id']||0])
                    this.measurements[element['group_id']||0] = []
                this.measurements[element['group_id']||0].push(new BatteryMeasurement(element.cellCount || parseInt(this.batteryItemData.battery.voltage/2), element, null, element['group_id']||0))
            }
        }
    }
    /**
     * Load all available data about battery from IndexedDB
     */
    async loadBatteryItemData(){
        const response = await this.indexedDb.getItem("batteries", this.id)
        response.item = JSON.parse(response.item)
        this.cellData = response.item.cellData
        this.examinedProperties = response.item.examinedProperties
        this.batteryItemData = response.item.batteryItemData
        this.parts = response.item.parts || []
        this.measurements = {}
        Object.values(response.item.measurements).forEach(group => {
            group.forEach((element)=>{
                if(!this.measurements[element['group_id']||0])
                    this.measurements[element['group_id']||0] = []
                this.measurements[element['group_id']||0].push(new BatteryMeasurement(element.measurements.length, element))
            })
        });
    }
    /**
     * Store Object's current state into IndexedDB
     */
    async saveBatteryItemData(){
        await this.indexedDb.storeItem("batteries",
                JSON.stringify({parts: this.parts,measurements: this.measurements, examinedProperties: this.examinedProperties, batteryItemData: this.batteryItemData, cellData: this.cellData}),
                this.id, {worksheetId: this.worksheetId})

      console.log("Battery item data saved");
    }
    /**
     * Collect not uploaded pictures and upload all
     * @param {boolean} syncData Download fresh data after upload
     */
    async uploadPictures( syncData = true){
        if(!this.examinedProperties || !this.examinedProperties.examinedProperties){return;}
        this.examinedProperties.examinedProperties.forEach((exProp=>{
            (exProp.problems || []).forEach((problem)=>{
                /*if(!problem.imageData || problem.imageData.startsWith('http')) { return }
                const response = await axios(configWithAuth('POST','image-manager/upload-problem-image',
                    {
                        worksheetItemId: this.id,
                        file: problem.imageData,
                        problemId: problem.selected.id || null,
                    }
                ))
                if(response.data.success) {problem.imageData = response.data.data}*/
                (problem.imagesData || []).forEach(async image=>{
                  const response = await axios(configWithAuth('POST','image-manager/upload/worksheet/problem-image',
                    {
                        worksheetItemId: this.id,
                        file: image,
                        problemId: problem.id || null,
                    }
                  ))
                  if(response.data.success) {problem.imagesData.splice(problem.imagesData.indexOf(image),1)}
                })
            })
        }))
        if(syncData){
          this.downloadProblemImages()
        }
    }
    /**
     * Upload just refill status (useful for refill only items)
     * @returns {AxiosResponse} Server's response
     */
    async uploadRefillStatus(){
        const postData = {
            worksheetItemId: this.id,
            refillComplete: this.examinedProperties['refill_complete']
        }
        return await axios(configWithAuth('POST','worksheets/save-water-refill-status',{data: postData}))
    }
    /**
     * Transform and upload parts used on this item (not saves assignments for problems)
     */
    async uploadParts(){
        const partsPostData = this.getPartsForUpload()
        const response = await axios(configWithAuth('POST','worksheets/save-parts',
            {
                worksheetItemId: this.id,
                parts: partsPostData
            }
        ))
        if(response.data.success){
            this.importParts(response.data.data)
        }
    }
    /**
     * Upload BatteryMEasurement-s and download the fresh data from server
     * @returns Server's response
     */
    async uploadMeasurements(){
        const syncedData = await axios(configWithAuth('POST','worksheets/save-battery-measurements',{ measurements: this.measurements, worksheetItemId: this.id}))
        this.downloadMeasurements(syncedData.data.data)
        return syncedData.data
    }
    /**
     * Upload examined properties and download the fresh data from server
     * @returns Server's response
     */
    async uploadExaminedProperties(){
        if(this.parts && this.parts.length > 0) { await this.uploadParts() }
        return await axios(configWithAuth('POST','worksheets/save-examined-properties',{
            data: {
              ...this.examinedProperties,
              dataTableImage: this.batteryItemData.dataTableImage
            }
          }))
    }
    /**
     * Delete all data from IndexedDB
     */
    async deleteLocalData(){
        await this.indexedDb.deleteItem(this.id,'batteries')
    }
    /**
     * Add user friendly name for all measurements
     */
    updateMeasurementsTitle(){
        let elapsedTime = 0
        Object.values(this.measurements).forEach((group)=>{
            elapsedTime = 0
            group.forEach((measurement,index) => {
            if(index === 0){ measurement.title = 'Nyugalmi állapot'}
                else{
                    elapsedTime = parseInt(elapsedTime) + parseInt(group[0]['load_duration'])
                    measurement.title = 'Mérés: ' + elapsedTime + ". perc"
                }
            })
        });
    }
    /**
     * Create new measurement
     * @param {Array} group Append this group with new measurement. Auto generate group if not set.
     * @returns {BatteryMeasurement} Reference of created measurement
     */
    newMeasurement(group){
        let newGroupId = null
        if(!group){
            newGroupId = Math.max(0,...Object.keys(this.measurements).map(key=> parseInt(key)))+1
            this.measurements[newGroupId] = []
            group = this.measurements[newGroupId]
        }
        group.push(new BatteryMeasurement(this.cellData.length || parseInt(this.batteryItemData.battery.voltage/2),null,null,newGroupId || group[0]['group_id']))
        const newMeasurement = group[group.length-1]
        newMeasurement['measurement_round'] = group.length-1
        newMeasurement.addAcidLevels()
        if(!group[0].measurement_type){group[0].measurement_type = "1"}
        this.updateMeasurementsTitle()
        return newMeasurement
    }
    /**
     * Delete specified measurement and save changes to IndexedDB
     * @param {Array} group The group to delete from
     * @param {int} index Index of item to delete
     */
    deleteMeasurement(group,index){
        group.splice(index, 1)
        let key
        Object.keys(this.measurements).forEach( gKey => {
            if(this.measurements[gKey].length === 0){
                key = gKey
            }
        })
        if(key){ delete this.measurements[key] }
        this.saveBatteryItemData()
    }
    /**
     * Upload and then download all data
     */
    async syncData(){
      if(!this.batteryItemData.modified){return}
        await this.saveBatteryItemData()
        await this.uploadExaminedProperties()
        await this.uploadMeasurements()
        await this.uploadPictures()
        await this.downloadBatteryItemData()
        this.updateMeasurementsTitle()
        this.batteryItemData.modified = false
        this.examinedProperties.forceUpdate = false
    }
    /**
     * Mark battery as not found and save data locally
     */
    async toggleItemNotFound(){
        this.examinedProperties['device_not_found'] = !this.examinedProperties['device_not_found']
        this.saveBatteryItemData()
        this.batteryItemData.modified = true

        this.examinedProperties.forceUpdate = true
        /*this.$http(configWithAuth('post','worksheets/toggle-device-not-found',{id: this.id})).then(async response =>{
            if(response.data.success){
                this.batteryItemData['device_not_found'] = !this.batteryItemData['device_not_found']
                this.saveBatteryItemData()
            }
        }).catch(async () => {
            this.batteryItemData['device_not_found'] = !this.batteryItemData['device_not_found']
            this.saveBatteryItemData()
        })*/
    }
    /**
     * Calculates how many examined property has checked
     * @returns {int} The index of last passed property
     */
    getPassedIndex(){
        let passedIndex = 0
        for (const element of this.examinedProperties.examinedProperties){
            if(element['is_examined'] && (element.passed || element.problems.length>0))
                passedIndex += 1
            else
                break
        }
        return passedIndex
    }
    /**
     * Make object capable for groupped measurement (not maintained)
     */
    initGroupMeasurementData(){
        this.groupMeasurementData.labelToUpload = this.batteryItemData.battery['serial_number']
        this.groupMeasurementData.newMeasurement = 'true'
        this.groupMeasurementData.isNewgroup = 'false'
        this.groupMeasurementData.selectedForMeasure = null
    }
}
