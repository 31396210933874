/**
 * Handle IndexedDB connection and manage
 * database creation, read and write transactions.
 * Only create databases for parts storing.
 */

export default class PartsDB{

    db = null //Reference to IndexedDB connection
    dbVersion = 1 //IndexedDB version. Increase if database structure has modified

    /**
     * Close database connection
     */
    closeDb(){
      this.db.close()
    }
    /**
     * Delete entire database from IndexedDb
     * @returns result
     */
    deleteDatabase(){
        return new Promise ((resolve) => {
            console.log('delete')
            const deleteReq = indexedDB.deleteDatabase("parts-db")
            console.log(deleteReq)
            deleteReq.onsuccess = (e)=>{
                return resolve(e)
            }
            deleteReq.onerror = (error)=>{
                console.log(error)
            }
            deleteReq.onupgradeneeded = (error)=>{
                console.log(error)
            }
        })
    }
    /**
     * Create database structure if not exists or outdated and create connection with database
     * @returns Reference to IndexedDB connection
     */
    createDatabase(){
        if (!('indexedDB' in window)) {
            console.log('This browser doesn\'t support IndexedDB');
            return;
        }
        return new Promise ((resolve) => {
            const indexedDB = window.indexedDB
            indexedDB.databases().then(async (databases) =>{
                if(databases[0] && databases[0].version !== this.dbVersion){
                    await this.deleteDatabase()
                }
                const open = indexedDB.open("parts-db", this.dbVersion);
                open.onupgradeneeded = async (e) =>{
                    console.log('create')
                    const db = e.target.result;

                    if (!db.objectStoreNames.contains('parts')) {
                        const partsStore = db.createObjectStore('parts', { keyPath: "id" });
                        partsStore.createIndex("part_type_id", "part_type_id", { unique: false });
                        partsStore.createIndex("groupName", "groupName", { unique: false });
                    }
                    if (!db.objectStoreNames.contains('groups')) {
                        db.createObjectStore('groups', { keyPath: "id" });
                    }
                };
                open.onsuccess = ()=>{
                    return resolve(open.result)
                }
                open.onerror = async (error) => {
                    if(error.target.error.name === "VersionError"){
                        indexedDB.deleteDatabase("parts-db")
                        return resolve(await this.createDatabase())
                    }
                }
            })


        });
    }
    /**
     * Put data into database
     * @param {string} storeName name of the indexedDB store
     * @param {Object} item Item to store
     * @param {int|string} id Primary key for item to store
     * @param {Array<int|sting>} primaryKeys Other indexes of the item to use them as keys
     * @returns {Promise} Promise of storing process. If it complete, returns transaction result
     */
    async storeItems(storeName,items){
        this.db = await this.createDatabase()
        return new Promise ((resolve) => {
            const tx = this.db.transaction([storeName], "readwrite")
            const objectStore = tx.objectStore(storeName);

            items.forEach(element => {
                objectStore.put(element)
            });

            tx.onsuccess= ()=>{
                resolve(tx.result)
            }
            tx.onerror = (err)=> {
                resolve(err)
            }
        })
    }
    /**
     * Read item from database by id
     * @param {string} storeName name of the store
     * @param {int|sting} id id of the item
     * @returns {Promise} Promise of the loading process. After it complete returns the requested item
     */
    async getItem(storeName, id){
        this.db = await this.createDatabase()
        return new Promise ((resolve) => {
            const objectStore = this.db.transaction(storeName).objectStore(storeName)
            const getRequest = objectStore.get(parseInt(id))
            getRequest.onsuccess = function() {
                resolve(getRequest.result)
            }
        })
    }
    /**
     * Get all items from an Object store
     * @param {string} storeName Name of an object store
     * @returns Promise of the loading process. After it complete returns the requested items
     */
    async getAll(storeName){
        this.db = await this.createDatabase()
        return new Promise ((resolve) => {
            const objectStore = this.db.transaction(storeName).objectStore(storeName)
            const getRequest = objectStore.getAll()
            getRequest.onsuccess = function() {
                resolve(getRequest.result)
            }
        })
    }
    /**
     * Get all items from database with specified index value
     * @param {string} storeName Name of the store
     * @param {string} indexName Name of indexed key
     * @param {int|string} id Key value
     * @returns Promise of the loading process. After it complete returns the requested items
     */
    async getAllByKey(storeName, indexName, id){
        await this.createDatabase()
        return new Promise ((resolve) => {
            const store = this.db.transaction(storeName).objectStore(storeName)
            const storeIndex = store.index(indexName)
            let indexes = storeIndex.getAllKeys(parseInt(id))

            indexes.onsuccess = async () =>{
                indexes = indexes.result
                const result = []
                for (const i of indexes) {
                    result.push(await new Promise (function(resolve) {
                        const getRequest = store.get(i)
                        getRequest.onsuccess = function() {
                            resolve(getRequest.result)
                        }
                    }))
                }
                resolve(result)
            }
        })
    }

}
